import React,{useState, useEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from '@mui/icons-material/Search';
import MainListItems from './listItems';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import logo from './FMCC CUTS.svg';
import './dashboard.css';
import CustomRoutes from '../../Routes/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../redux/authentication/actionCreator';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Alert, Autocomplete, Button, DialogActions, DialogTitle, FormControl, FormHelperText, InputLabel, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { makeStyles } from '@mui/styles';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import { getuserinfo, updategetuserinfo } from '../../lib/api-users';
import { getAllUniqueWRNumbers, getAllWRNumbers, getSearchedValue } from '../../lib/api-excelImport';
import CustomSnackbar from '../ui/SnackbarComponent';
import { DataGrid } from '@mui/x-data-grid';
import { getGlobalSearchedData } from '../../lib/api-records';
import GlobalSearch from '../../Dashboard-reports/Global Search/GlobalSearch';
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/" sx={{ textDecoration: 'none' }}>
        FMCC PATCH
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
 
const drawerWidth = 242;
 
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    backgroundColor: '#d3252b',
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));
 
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      backgroundColor: '#3d3d3d',
      color: 'white',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9)
        }
      })
    }
  })
);
 
const defaultTheme = createTheme();
 
const settings = [
  {
    name: 'Profile',
    icon: <PersonIcon />
  },
  {
    path: '/',
    name: 'Logout',
    icon: <LockIcon />
  }
];




const useStyles = makeStyles({
  hidePagination: {
    '& .MuiDataGrid-footerContainer': {
      display: 'none'
    }
  },
  blueHeader: {
    backgroundColor: '#2595be9c',
    color: '#000000'
  }

});



export default function Dashboard() {
  const classes = useStyles();
  const location = useLocation();
  const user = useSelector((state) => state.auth);
  const [inputval,setInputval]= useState({
    id:'',
    fname:'',
    lname:'',
    email:'',
    contactno:'',
    password:''
  })
 
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticate = useSelector(({ auth }) => auth.login);
  const [open, setOpen] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [options, setOptions] = useState([]);
  const [checkUser, setCheckUser] = useState(false);
  const [confirmmessage,setConfirmmessage] = useState("")
  const [isConfirmationopen,setConfirmationopen] = useState(false);
  const [openGlobalSearch, setOpenGlobalSearch] = useState(false);
  const [rows,setRows]= useState([]);
  const [selectTypeValue, setGlobalSearchValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [globalError, setGlobalError] = useState('');
  const [selectTypeError, setSelectTypeError] = useState(false);
  const [searchValueError, setSearchValueError] = useState(false);

  const navigate = useNavigate();
 
  React.useEffect(() => {
    if (!isAuthenticate) {
      navigate('/');
    }
  }, [isAuthenticate]);



  React.useEffect(() => {
    if (user.roleid !== 2) {
      setCheckUser(true)
    }
  }, [user]);


 
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
 
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
 
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
 
  const toggleDrawer = () => {
    setOpen(!open);
  };
 
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(userLogout());
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    getuserdata()
  };
 
  const handleCloseModal = () => {
    setOpenModal(false);
  };




  const columns = [
    { headerClassName: classes.blueHeader,field: 'wrNo', headerName: 'wrNo', flex:1},
    {
      headerClassName: classes.blueHeader,
      field: 'bcaId',
      headerName: 'BCA-ID',
      flex:1
    },
    {
      headerClassName: classes.blueHeader,
      field: 'address',
      headerName: 'Address',
      flex:1
    }
  ];








 
  async function getuserdata() {
    const id = user.id;
 
    try {
      setLoading(true);
      const response = await getuserinfo(id);
      const responseData = response.data.user;
 
      console.log("Response Data from API:", responseData);
 
      // Update the state with the obtained user data
      setInputval({
        id:responseData.id || '',
        fname: responseData.fname || '',
        lname: responseData.lastName || '',
        email: responseData.emailid || '',
        contactno: responseData.contactNo || '',
        password: responseData.password || ''
      });
 
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.data && e.response.data.error) {
        console.log(e.response.data.message);
      } else {
        console.log("Something went wrong!");
      }
    }
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputval((prevInputval) => ({
      ...prevInputval,
      [name]: value
    }));
  };
  const handleUpdate = async () => {
    try {
      setLoading(true);
      const response = await updategetuserinfo(inputval); // You need to implement this function
      console.log('Response from backend:', response);
      handleCloseModal()
      setLoading(false);
      // Optionally, you can update the state or handle the response as needed
    } catch (error) {
      console.error('Error updating user info:', error);
      setLoading(false);
      // Handle errors or display a message to the user
    }
 
  };



 
   const FetchUniqueWrList = async () => {
      try {
        const response = await getAllUniqueWRNumbers();
      
        setOptions(() => {
          const wrNumbersOp = response.data.map((row) => {
            return { value: row.id, label: row.wrNo };
          });
          return wrNumbersOp;
        });
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  
  useEffect(() => {
      FetchUniqueWrList();
  }, []);




  const navigatePage = (value)=>{
    if(window.location.href.endsWith(value)) {
      const home = document.getElementById('list-home-list')
      home && home.click()
      }
      navigate(`/cutInfo/${value}`);
  }


const handleValue = (event,value)=>{
  if(event.key === 'Enter'){
    const filterData = options.filter(option=> option.label == event.target.value)
    console.log('wht is filter dataaa',filterData);
    if(filterData.length){
      navigatePage(filterData[0].value);
      setSearchFieldValue('');
    }else{
      setConfirmmessage('wrNo Not Exist !!');
      setSearchFieldValue('');
      setConfirmationopen(true);
    }
  } 
}




const handleGlobalSearchModal = ()=> setOpenGlobalSearch(true);

const handleGlobalSearchClose = ()=> {
      setOpenGlobalSearch(false);
      setGlobalSearchValue('');
      setSearchValue('');
      setRows([]);
}


const handleGlobalSearchBtn = async () => {

  const trimmedSearchValue = searchValue.trim();
  let valid = true;

  // Validation check
  if (!selectTypeValue) {
    setSelectTypeError(true);
    valid = false;
  } else {
    setSelectTypeError(false);
  }

  if (!trimmedSearchValue) {
    setSearchValueError(true);
    valid = false;
  } else {
    setSearchValueError(false);
  }

  if (!valid) return;

  if (selectTypeValue === 'wrNo') {
    const filterData = options.filter(option => option.label == trimmedSearchValue);
    if (filterData.length) {
      setOpenGlobalSearch(false);
      setGlobalSearchValue('');
      navigatePage(filterData[0].value);
      setSearchValue('');
      setGlobalError('');
      return;
    } else {
      setGlobalError('wrNo Not Exist !!');
      setSearchValue('');
    }
  } else {
    setLoading(true);
    const response = await getGlobalSearchedData({ type: selectTypeValue, value: trimmedSearchValue });
    if (response.status === 200) {
      setGlobalError('');
      setLoading(false);
      navigate('/Global-Search', { state: { tableData: response.data.data} });
      setGlobalSearchValue('');
      setSearchValue('');
    } else if (response.status === 201) {
      setLoading(false);
      setGlobalError(response.data.message);
      setSearchValue('');
    }
  }
};









  return (
  <>
    {loading && <LoadingSpinner />}
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px',
              backgroundColor: '#d3252b'
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            ></Typography>



<Toolbar>


        {/* <Grid item xs={8}>
          {checkUser && (
       <TextField
       placeholder='Enter WrNo'
       variant="outlined"
       fullWidth
       size="small"
       name="searchField"
       onKeyDown={(event)=> handleValue(event)}
       sx={{width:300, backgroundColor:'white', borderRadius:'6px'}}
       value={searchFieldValue}
       onChange={(event) => setSearchFieldValue(event.target.value)}
       />
          )}
 
        </Grid> */}

<Grid container spacing={1} sx={{marginRight:5}}>
        <Grid item  md={4.5}>
        <FormControl fullWidth error={selectTypeError}>
  {/* <InputLabel
    id="demo-simple-select-helper-label"
    sx={{
      color: 'black',
      fontSize: 15,
      '&.Mui-focused': {
        color: 'black', // Hide the label when focused
      },
    }}
  >
    Select Type
  </InputLabel> */}
  <Select
    labelId="demo-simple-select-helper-label"
    id="demo-simple-select-helper"
    value={selectTypeValue == 'wrNo' && 'WR No' || selectTypeValue == 'bcaId' && 'BCA-ID' || selectTypeValue == 'address' && 'Address'}
    placeholder="Select Type"
    displayEmpty // Ensures the placeholder is shown when no value is selected
    renderValue={(selected) =>
      selected ? selected : <span style={{ color: 'grey', fontFamily:'roboto', fontSize: 14.8 }}>Select Type</span> // Placeholder styling
    }
    sx={{
      backgroundColor: 'white',
      borderRadius: '6px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white', // Default border color
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white', // Border color on hover
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white', // Border color when focused
      },
    }}
    size="small"
    onChange={(e) => setGlobalSearchValue(e.target.value || '')}
  >
    <MenuItem value="wrNo">WR No</MenuItem>
    <MenuItem value="bcaId">BCA-ID</MenuItem>
    <MenuItem value="address">Address</MenuItem>
  </Select>
  {selectTypeError && <FormHelperText>Select Type is required</FormHelperText>}
</FormControl>




          </Grid>
        <Grid item  md={5.5} >
        <TextField
  id="outlined-basic"
  placeholder="Enter Search Value"
  error={searchValueError}
  helperText={searchValueError ? "Search value is required" : ""}
  value={searchValue}
  onChange={(e) => setSearchValue(e.target.value)}
  variant="outlined"
  sx={{
    backgroundColor: 'white',
    borderRadius: '6px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Default border color
      },
      '&:hover fieldset': {
        borderColor: 'white', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Border color when focused
      },
      '& input::placeholder': {
        color: 'black', // Placeholder color
        fontSize: 14.8, // Placeholder font size
      },
    },
    '& .MuiInputLabel-root': {
      color: 'black',
      fontSize: 15, // Default label color
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black', // Label color when focused
    },
  }}
  size="small"
  fullWidth
/>
          </Grid>
          <Grid item md={2}>
          <Button variant="contained" sx={{backgroundColor:'#000000'}} onClick={handleGlobalSearchBtn} fullWidth>
             Search
            </Button>
          </Grid>
          </Grid>

          {globalError && (
  <Alert  severity="error" sx={{marginLeft:2, marginRight:2}}>
    {globalError}
</Alert>
)}

{/* <Grid item xs={8}>
          {checkUser && (
    <Button variant="contained" onClick={handleGlobalSearchModal} sx={{backgroundColor:'white',color:'black' ,'&:hover': {
      backgroundColor: 'white', 
    },}}  startIcon={<SearchIcon />}>
    Search ...
  </Button>
          )}
 
        </Grid> */}
   
      </Toolbar>





            <Box sx={{ flexGrow: 0 }}>
             

              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="https://mui.com/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, index) => {
                
             
                   return <MenuItem
  key={setting.name === 'Profile'}
  onClick={(e) => {
    handleCloseUserMenu();
    handleOpenModal();
    if(setting.name === 'Logout'){
      handleLogout(e);
    }
  }}
  sx={{ width: 120 }}
>
  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 0 }}>
    <Grid item xs={6}>
      <div style={{ color: 'red' }}>{setting.icon}</div>
    </Grid>
    <Grid item xs={6}>
      <Typography textAlign="center" sx={{ color: 'black' }}>
        {setting.name}
      </Typography>
    </Grid>
  </Grid>
</MenuItem>
 
        
                })}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1]
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon sx={{ color: 'white' }} />
            </IconButton>
          </Toolbar>
          <img src={logo} alt="" id="Logo" />
          <List component="nav">
            <MainListItems />
            <Divider sx={{ backgroundColor: 'white', marginTop: '190px' }} />
            <Copyright sx={{ pt: 4, color: 'white' }} />
          </List>
        </Drawer>
        <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Edit Profile</DialogTitle>
          <DialogContent>
 
          <Grid container spacing={2} sx={{marginTop:'5px'}}>
  <Grid item xs={6}>
  <TextField  label="First Name" variant="outlined"  value={inputval.fname} name='fname' onChange={handleInputChange} fullWidth size="small"/>
  </Grid>
  <Grid item xs={6}>
  <TextField  label="Last Name" variant="outlined" value={inputval.lname}  name='lname' onChange={handleInputChange} fullWidth size="small"/>
  </Grid>
  <Grid item xs={6}>
  <TextField  label="Email" variant="outlined" value={inputval.email} name='email' onChange={handleInputChange} fullWidth size="small"  />
  </Grid>
  <Grid item xs={6}>
  <TextField  label="Contact Number" variant="outlined" value={inputval.contactno} name='contactno' onChange={handleInputChange} fullWidth size="small"  />
  </Grid>
  <Grid item xs={6}>
  <TextField  label="Password" variant="outlined" value={inputval.password} name='password' onChange={handleInputChange}  fullWidth size="small"  />
  </Grid>
</Grid>
<Grid container spacing={2}>
  <Grid item xs={2}>
  <Button
       
        variant="contained"
        style={{
          minWidth: '90px',
          backgroundColor: 'black',
          color: '#fff',
          marginTop: '10px',
        }}
        onClick={handleUpdate}
      >
        Update
      </Button>
  </Grid>
  <Grid item xs={2}>
  <Button
        variant=""
        style={{
          minWidth: '90px',
          color: '#fff',
          backgroundColor: 'red',
          marginTop:'10px'
        }}
       onClick={handleCloseModal}
      >
        Cancel
      </Button>
  </Grid>
 
</Grid>
 
          </DialogContent>
        </Dialog>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            // height: '100vh',
            height: '100wh'
          }}
        >
          <Toolbar />
          <Container maxWidth="auto" sx={{ mt: 4, mb: 4 }}>
            <CustomRoutes />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>

    <CustomSnackbar
      open={isConfirmationopen}
      message={confirmmessage}
      onClose = {()=> setConfirmationopen(false)}
      color={'error'}
      />



    </>
  );
}