import axios from 'axios';
import { RECORDS_BASE_PATH } from './api-base-paths';

export const getAllWrNo = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getTotalWr`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getTotalWorkOrdersRecord = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getTotalWorkOrdersRecord`);
  } catch (error) {
    console.error('Error fetching total work orders:', error);
    throw error;
  }
};






export const getCompletedWrNo = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getCompletedWrNo`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getPendingApproval = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getPendingApproval`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getCuIdPendingInvoiceRecords = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getCuIdPendingInvoiceRecords`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getConstructionPermitRecords = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getConstructionPermitRecords`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};


export const getRecordsWithNoConstructionPermit = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getRecordsWithNoConstructionPermit`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getOccupancyPermitRecords = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getOccupancyPermitRecords`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};


export const getOccupancySearchedValue = (data) => {
  return axios.post(`${RECORDS_BASE_PATH}/getOccupancySearchedValue`, data);
};



export const getOccupancyPermitStatus = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getOccupancyPermitStatus`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};





export const getExceptionCount = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getExceptionCount`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};


export const getExceptionRecords = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getExceptionRecord`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};





export const cuForSubmissionCount = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/cuForSubmissionCount`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};




export const UnsubmittedCURecords = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/UnsubmittedCURecords`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const UnsubmittedCURecordsCount = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/UnsubmittedCURecordsCount`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getUnsubmittedSearchedValue = (data) => {
  return axios.post(`${RECORDS_BASE_PATH}/getUnsubmittedSearchedValue`, data);
};


export const SubmittedCuRecords = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/SubmittedCuRecords`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const SubmittedCuRecordsCount = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/SubmittedCuRecordsCount`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getSubmittedSearchedValue = (data) => {
  return axios.post(`${RECORDS_BASE_PATH}/getSubmittedSearchedValue`, data);
};



export const RejectedCuIds = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/rejectedCuIds`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};




export const getPendingBill = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getPendingBill`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getNoPermit = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getNoPermit`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getPermitExpire = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getPermitExpire`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getNewWorkOrder = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getNewWorkOrder`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getWGInCut = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getWGInCut`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getOwnerCooperation = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getOwnerCooperation`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getAllWrNoEntries = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getTotalWrEntries`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getCompletedWrNoEntries = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getCompletedWrNoEntries`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getPriorityWrRecords = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getPriorityWrRecords`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};


export const getComplaintWrRecords = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getComplaintWrRecords`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getPendingApprovalEntries = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getPendingApprovalEntries`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getPendingInvoiceEntries = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getPendingInvoiceEntries`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getNoPermitEntries = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getNoPermitEntries`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getPermitExpireEntries = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getPermitExpireEntries`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getEndDateApproaching = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getEndDateApproachingCount`);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};



export const getConcreteRecords = (data) => {
  try {
    return axios.post(`${RECORDS_BASE_PATH}/getConcreteRecords`,data);
  } catch (error) {
    console.error('Error fetching Concrete Records:', error);
    throw error;
  }
};



export const getAsphaltRecords = (data) => {
  try {
    return axios.post(`${RECORDS_BASE_PATH}/getAsphaltRecords`,data);
  } catch (error) {
    console.error('Error fetching Asphalt Records:', error);
    throw error;
  }
};



export const generateConcretePDF = (data) => {
  try {
    return axios.post(`${RECORDS_BASE_PATH}/generateConcretePDF`,data);
  } catch (error) {
    console.error('Error Generating Concrete PDF:', error);
    throw error;
  }
};


export const generateAsphaltPDF = (data) => {
  try {
    return axios.post(`${RECORDS_BASE_PATH}/generateAsphaltPDF`,data);
  } catch (error) {
    console.error('Error Generating Asphalt PDF:', error);
    throw error;
  }
};



export const getGlobalSearchedData = (data) => {
  try {
    return axios.post(`${RECORDS_BASE_PATH}/getGlobalSearchedData`,data);
  } catch (error) {
    console.error('Error Generating Global Searched Data:', error);
    throw error;
  }
};




export const getKpiRecordsData = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getKpiRecordsData`);
  } catch (error) {
    console.error('Error fetching KPI Records:', error);
    throw error;
  }
};





export const getTotalAndCompletedWorkData = () => {
  try {
    return axios.get(`${RECORDS_BASE_PATH}/getTotalWorkOrderAndComplete`);
  } catch (error) {
    console.error('Error fetching Dashboard total and completed counts :', error);
    throw error;
  }
};